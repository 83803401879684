import request from './request'

//审批流程列表
export const examine_index_request = p => {
    return request({
        method:'GET',
        url:'examine/index',
        params: p
    })
}

//添加审批流程
export const examine_add_request = d => {
    return request({
        method:'POST',
        url:'examine/add',
        data: d
    })
}

//删除审批流程
export const examine_del_request = id => {
    return request({
        method:'DELETE',
        url:'examine/del',
        data: {
            id: id
        }
    })
}

//修改审批流程
export const examine_edit_request = data => {
    return request({
        method:'PUT',
        url:'examine/edit',
        data
    })
}

//获取审批流程详情
export const examine_details_request = id => {
    return request({
        method:'GET',
        url:'examine/details',
        params: {
            id
        }
    })
}