<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="60%"
            :center="true"
            top="8vh"
            :close-on-click-modal="false"
            :before-close="() => closeExamineDialog('ExamineEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="Examine"
                    :rules="rules"
                    ref="ExamineEditForm"
                    label-width="100px"
                    size="small"
                    label-position="top"
                >
                    <div v-if="show_type">
                        <fieldset class="fieldsetc">
                            <legend class="legendc">关联信息</legend>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="使用部门" prop="department_id">
                                        <el-cascader
                                            v-model="Examine.department_id"
                                            :options="fenterprise_department"
                                            :props="{ expandTrigger: 'hover' }"
                                            clearable
                                            style="width: 100%"
                                        >
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="所属类别" prop="examine_type">
                                        <el-select
                                            v-model="Examine.examine_type"
                                            placeholder="选择类别"
                                            class="examine_typec"
                                            @change="(e) => ExamineTypeChange(e)"
                                            clearable
                                            filterable
                                        >
                                            <el-option
                                                v-for="(examine_type) in this.$store.state.examine_type"
                                                :key="examine_type.name"
                                                :label="examine_type.name"
                                                :value="examine_type.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </fieldset>
                        <fieldset class="fieldsetc ExamineEditc">
                            <legend class="legendc">审批流程</legend>
                            <el-row :gutter="20">
                                <el-col :span="2" class="row_title"><el-form-item>序号</el-form-item></el-col>
                                <el-col :span="5" class="row_title"><el-form-item>审批人</el-form-item></el-col>
                                <el-col :span="5" class="row_title"><el-form-item>条件</el-form-item></el-col>
                                <el-col :span="8" class="row_title"><el-form-item>标准</el-form-item></el-col>
                                <el-col :span="4" class="row_title"><el-form-item></el-form-item></el-col>
                            </el-row>
                            <el-row  :gutter="20" v-for="(value,index) in Examine.examine_staff" :key="'审批人' + index">
                                <el-col :span="2">
                                    <el-form-item style="text-align: center;">
                                        {{index + 1}}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item
                                        :prop="'examine_staff.' + index + '.staff_id'"
                                        :rules="{ 
                                            required: true,
                                            message: '审批人不能为空',
                                            trigger: 'blur' 
                                        }">
                                        <el-select
                                            v-model="value.staff_id"
                                            placeholder="选择审批人"
                                            class="staff_idc"
                                            clearable
                                            filterable
                                        >
                                            <el-option
                                                v-for="(staff) in AllStaff"
                                                :key="staff.name + index"
                                                :label="staff.name"
                                                :value="staff.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item :prop="'examine_staff.' + index + '.where'">
                                        <el-select
                                            v-model="value.where"
                                            placeholder="审批标准（选填）"
                                            class="wherec"
                                            clearable
                                        >
                                            <el-option
                                                v-for="(v) in AllWhere"
                                                :key="v.name + index"
                                                :label="v.name"
                                                :value="v.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item :prop="'examine_staff.' + index + '.standard'">
                                        <el-select
                                            v-if="(value.where==='client_id_dy') || (value.where==='client_id_bdy')"
                                            v-model="value.standard"
                                            filterable
                                            placeholder="审批标准（选填）"
                                            class="clientc"
                                            clearable
                                            size="small"
                                        >
                                            <el-option
                                                v-for="client in AllClient"
                                                :key="client.name"
                                                :label="client.name"
                                                :value="client.id"
                                            ></el-option>
                                        </el-select>
                                        <el-select
                                            v-else-if="(value.where==='entry_staff_id_dy') || (value.where==='entry_staff_id_bdy') || (value.where==='create_staff')"
                                            v-model="value.standard"
                                            filterable
                                            placeholder="审批标准（选填）"
                                            class="clientc"
                                            clearable
                                            size="small"
                                        >
                                            <el-option
                                                v-for="(staff) in AllStaff"
                                                :key="staff.name + index + 'entry_staff'"
                                                :label="staff.name"
                                                :value="staff.id"
                                            ></el-option>
                                        </el-select>
                                        <el-select
                                            v-else-if="(value.where==='enterprise_id_dy') || (value.where==='enterprise_id_bdy')"
                                            v-model="value.standard"
                                            filterable
                                            placeholder="审批标准（选填）"
                                            class="clientc"
                                            clearable
                                            size="small"
                                        >
                                            <el-option
                                                v-for="(enterprise) in AllEnterprise"
                                                :key="enterprise.name + index"
                                                :label="enterprise.name"
                                                :value="enterprise.id"
                                            ></el-option>
                                        </el-select>
                                        <el-select
                                            v-else-if="value.where==='domestic_foreign'"
                                            v-model="value.standard"
                                            filterable
                                            placeholder="审批标准（选填）"
                                            class="clientc"
                                            clearable
                                            size="small"
                                        >
                                            <el-option
                                                v-for="(v,i) in ['内贸','外贸']"
                                                :key="v + i"
                                                :label="v"
                                                :value="v"
                                            ></el-option>
                                        </el-select>
                                        <el-select
                                            v-else-if="value.where==='mark'"
                                            v-model="value.standard"
                                            filterable
                                            placeholder="审批标准（选填）"
                                            class="clientc"
                                            clearable
                                            size="small"
                                        >
                                            <el-option
                                                v-for="(v,i) in ['普通假','特殊假']"
                                                :key="v + i"
                                                :label="v"
                                                :value="i"
                                            ></el-option>
                                        </el-select>
                                        <el-select
                                            v-else-if="value.where==='rank'"
                                            v-model="value.standard"
                                            filterable
                                            placeholder="审批标准（选填）"
                                            class="clientc"
                                            clearable
                                            size="small"
                                        >
                                            <el-option
                                                v-for="(v,i) in ['经理级别以下','经理及以上级别']"
                                                :key="v + i"
                                                :label="v"
                                                :value="i"
                                            ></el-option>
                                        </el-select>
                                        <el-input v-else v-model="value.standard" placeholder="审批标准（选填）"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item>
                                        <el-button type="success" plain icon="el-icon-plus" circle size="small" @click="AddExamineStaff(Examine.examine_staff,value)"></el-button>
                                        <el-button type="danger" plain icon="el-icon-delete" circle size="small" v-if="index !==0" @click.prevent="DelExamineStaff(Examine.examine_staff,value)"></el-button>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">抄送人</legend>
                            <el-row>
                                <el-form-item label="　" prop="cc_staff">
                                    <el-select v-model="Examine.cc_staff" filterable multiple placeholder="请选择抄送人" class="cc_staffc">
                                        <el-option
                                            v-for="item in AllStaff"
                                            :key="item.name"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-row>
                        </fieldset>
                    </div>
                    <div v-else>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">关联信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>使用部门</span>
                                    </template>
                                    <span>{{Examine.enterprise_name ? (Examine.enterprise_name + '/' + Examine.department_name) : ''}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>所属类别</span>
                                    </template>
                                    <span>{{Examine.examine_type_name}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc ExamineEditc">
                            <legend class="legendc">审批流程</legend>
                            <el-table
                                :data="Examine.examine_staff"
                                border
                                highlight-current-row
                                style="width: 100%"
                            >
                                <el-table-column align="center" type="index"> </el-table-column>
                                <el-table-column
                                    v-for="item in col_arr"
                                    align="center"
                                    :label="item.label"
                                    :key="item.prop"
                                >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row[item.prop] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </fieldset>
                        <fieldset class="fieldsetc ExamineEditc" v-if="Examine.cc_staff_name">
                            <legend class="legendc">抄送人</legend>
                            <el-row>
                                <el-form-item label="　" prop="cc_staff">
                                    {{Examine.cc_staff_name}}
                                </el-form-item>
                            </el-row>
                        </fieldset>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <!-- 复制按钮使用添加的权限 -->
                    <el-button
                        v-if="$_has(add_auth)"
                        type="warning"
                        plain
                        @click="copyTeam"
                        >复制</el-button
                    >
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="ExamineDel('ExamineEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeExamineDialog('ExamineEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('ExamineEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('ExamineEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeExamineDialog('ExamineEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('ExamineEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeExamineDialog('ExamineEditForm')"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { examine_add_request,examine_del_request,examine_edit_request } from '@/network/examine.js'

import { client_list_request,enterprise_list_request } from '@/network/list.js'

export default {
    name:'ExamineEditComponent',
    data(){
        return {
            login_loading: false,
            Examine: {
                department_id: '',
                examine_type: '',
                examine_staff: [
                    {
                        staff_id:'',
                        where: '',
                        standard: ''
                    }
                ],
                cc_staff: [],
                cc_staff_name: ''
            },
            rules: {
                department_id: [
                    {
                        required: true,
                        message: ' 请选择部门',
                        trigger: 'blur',
                    },
                ],
                examine_type: [
                    {
                        required: true,
                        message: ' 请选所属类别',
                        trigger: 'blur',
                    },
                ],
            },
            AllClient: [],
            AllWhere: [],
            ALllEnterprise: [],
            col_arr: [
                {
                    prop: 'staff_name',
                    label: '审批人'
                },
                {
                    prop: 'where_name',
                    label: '条件'
                },
                {
                    prop: 'standard_name',
                    label: '标准'
                },
            ]
        }
    },
    computed:{
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '审批流程详情'
            } else if (this.show_type === 1) {
                return '添加审批流程'
            } else {
                return '修改审批流程'
            }
        },
    },
    methods:{
        resetForm() {
            this.Examine = {
                department_id: '',
                examine_type: '',
                examine_staff: [
                    {
                        staff_id:'',
                        where: '',
                        standard: ''
                    }
                ],
                cc_staff: [],
                cc_staff_name: ''
            },
            this.AllWhere = []
        },
        closeExamineDialog() {
            this.resetForm()
            this.$emit('exitExamineDialog')
        },
        ExamineDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    examine_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeExamineDialog(formName))
                })
                .catch((err) => {})
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.ExamineInsert(formName)
            })
        },
        ExamineInsert(formName) {
            this.login_loading = true
            let d = this.Examine
            d.department_id = d.department_id[d.department_id.length - 1]
            examine_add_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitExamineDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.ExamineEdit(formName)
            })
        },
        ExamineEdit(formName) {
            this.login_loading = true
            let d = this.Examine
            d.id = this.id
            d.department_id = d.department_id[d.department_id.length - 1]
            examine_edit_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitExamineDialog')
                        this.$emit('search')
                        this.$emit('details_row',d.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        AddExamineStaff(item,v) {
            const index = item.indexOf(v)
            item.splice(index + 1,0,{
                staff_id:'',
                where: '',
                standard: '',
                key: Date.now()
            })
        },
        DelExamineStaff(item,v) {
            const index = item.indexOf(v)
            if (index !== -1) {
                item.splice(index, 1)
            }
        },
        ExamineTypeChange(e) {
            if(e !== '') {
                let w = []
                this.AllWhere = this.$store.state.examine_type.forEach(element => {
                    if(element.id === e) {
                        w = element.where
                        return
                    }
                })
                this.AllWhere = w
            } else {
                this.AllWhere = ''
            }
            this.Examine.examine_staff = [
                {
                    staff_id:'',
                    where: '',
                    standard: ''
                }
            ]
        },
        copyTeam() {
            this.Examine.department_id = ''
            this.$emit('addTeam')
        },
        get_all_client() {
            client_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllClient = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_enterprise() {
            enterprise_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllEnterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
    },
    filters:{},
    props:{
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        fexamine_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        fenterprise_department: Array,
        AllStaff: Array,
        edit_auth: String,
        del_auth: String,
        add_auth: String
    },
    created(){
        this.get_all_client()
        this.get_all_enterprise()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{
        fexamine_details_data(newVal) {
            this.Examine = newVal
            this.Examine.department_id = [this.Examine.enterprise_id,this.Examine.department_id]
            this.AllWhere = this.$store.state.examine_type[newVal.examine_type].where
        },
    }
}
</script>

<style lang='less'>
.examine_typec {
    width: 100%;
}
.row_title{
    text-align: center;
    color: cornflowerblue;
}
.fieldsetc {
    margin-bottom: 20px;
}
.legendc {
    color: #921AFF;
}
.ExamineEditc .el-form-item__content {
    text-align: center;
}
.cc_staffc {
    width: 100%;
}
.clientc {
    width: 100%;
}
</style>