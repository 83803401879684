<template>
    <div v-loading.fullscreen.lock="loading">
        <examine-search-bar-component
            add_auth="新增审批流程"
            :fAllenterprise="fAllenterprise"
            @search="get_examine_index"
            @addTeam="addTeam"
        ></examine-search-bar-component>
        <common-table-component
            details_auth="审批流程详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_examine"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_examine_index"
        ></common-page-component>
        <examine-edit-component
            edit_auth="修改审批流程"
            del_auth="删除审核流程"
            add_auth="新增审批流程"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :fenterprise_department="fenterprise_department"
            :AllStaff="AllStaff"
            :fexamine_details_data="fexamine_details_data"
            @show_edit="show_edit"
            @addTeam="addTeam"
            @search="get_examine_index"
            @exitExamineDialog="dialogExit"
            @details_row="details_examine"
        ></examine-edit-component>
    </div>
</template>

<script>
import { enterprise_department_request} from '@/network/enterprise.js'
import { examine_index_request,examine_details_request } from '@/network/examine.js'
import { enterprise_list_request,staff_list_request } from '@/network/list.js'

import ExamineSearchBarComponent from '@/components/examine/ExamineSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import ExamineEditComponent from '@/components/examine/ExamineEditComponent'

export default {
    name:'ExamineView',
    data(){
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: false,
            ftotal: 0,
            cond: {
                enterprise_id: '',
                department_id: '',
                examine_type: '',
                page: 1,
                limit: 10,
            },
            fcol_arr: [
                {
                    prop: 'enterprise_name',
                    label: '企业名称',
                    width:'260px'
                },
                {
                    prop: 'department_name',
                    label: '部门名称',
                    minWidth: '150px'
                },
                {
                    prop: 'examine_type_name',
                    label: '类别',
                    minWidth: '150px'
                },
                {
                    prop: 'examine_staff_name',
                    label: '审批人',
                    minWidth: '500px'
                },
                {
                    prop: 'cc_staff_name',
                    label: '抄送人',
                    minWidth: '170px'
                },
            ],
            ftable_data: [],
            fAllenterprise: [],
            fexamine_details_data: {},
            fenterprise_department:[],
            AllStaff: []
        }
    },
    computed:{},
    methods:{
        get_all_enterprise() {
            enterprise_list_request()
                .then((s) => {
                    this.fAllenterprise = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_examine_index(param = {}) {
            this.loading = true
            this.cond.enterprise_id = param.enterprise_id ?? this.cond.enterprise_id
            this.cond.department_id = param.department_id ?? this.cond.department_id
            this.cond.examine_type = param.examine_type ?? this.cond.examine_type
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            examine_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        details_examine(id) {
            this.fid = id
            this.fdialogFormVisible = true
            examine_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fexamine_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        get_enterprise_department() {
            this.loading = true
            enterprise_department_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.fenterprise_department = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_staff() {
            this.loading = true
            staff_list_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.AllStaff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_all_staff()
        this.get_all_enterprise()
        this.get_examine_index()
        this.get_enterprise_department()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        ExamineSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        ExamineEditComponent
    },
    watch:{}
}
</script>

<style lang='less'></style>